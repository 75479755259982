/*global THREE, TWEEN */
// REF: https://codepen.io/gnauhca/pen/VzJXGG?editors=0010
(function(window, document) {
	// import TWEEN from 'tween.js';

	/* 時間 */
	var TIME = {
		// 所有時間body物件
		bodys: [],
		delta: 16,
	};

	var stop = false;
	var t;
	TIME.addBody = function (timeBody) {
		this.bodys.push(timeBody);
	};

	TIME.removeBody = function (timeBody) {
		var index = this.bodys.indexOf(timeBody);

		if (index !== -1) {
			this.bodys.splice(index, 1);
		}
	};
	TIME.tick = (function () {
		var now = new Date().getTime();
		var last = now;
		var delta;
		return function () {
			delta = now - last;
			delta = delta > 500 ? 30 : delta < 16 ? 16 : delta;
			TIME.delta = delta;
			last = now;

			TIME.handleFrame(delta);
			if (!stop) {
				t = requestAnimationFrame(TIME.tick);
				// setTimeout(TIME.tick, 1000);
			}
		};
	})();

	TIME.start = function () {
		stop = false;
		cancelAnimationFrame(t);
		this.tick();
	};

	TIME.stop = function () {
		cancelAnimationFrame(t);
		stop = true;
	};

	TIME.handleFrame = function (delta) {
		TIME.bodys.forEach(function (body) {
			if (!body.isStop) {
				body.ticks.forEach(function (tick) {
					tick.fn && tick.fn(delta);
				});
			}
		});

		TWEEN.update();
	};

	// window.TIME = TIME;

	/* 時間物體類，提供兩個時機，幀更新，固定間隔更新，每一個有時間概念的物體，就繼承 */
	class Time {
		constructor() {
			this.ticks = [];
			this.tweens = [];
			this.isStop = false;
			TIME.addBody(this);
		}

		/**
		 * 該物體滅亡
		 */
		destroy() {
			TIME.removeBody(this);
		}

		/**
		 * 幀更新
		 * @param timegap 與上一幀的時間間隔
		 */
		addTick(fn) {
			var tick = { fn: fn.bind(this) };

			tick.isStop = false;
			this.ticks.push(tick);
			return tick;
		}

		removeTick(tick) {
			if (!tick) {
				// remove all
				this.ticks = [];
				return;
			}

			var index = this.ticks.indexOf(tick);

			if (index !== -1) {
				this.ticks.splice(index, 1);
			}
		}

		/**
		 * tween
		 */
		addTween(tween) {
			this.tweens.push(tween);
		}

		removeTween(tween) {
			if (!tween) {
				// remove all
				this.tween = [];
				return;
			}

			var index = this.tweens.indexOf(tween);

			if (index !== -1) {
				//tween.stop();
				this.tweens.splice(index, 1);
			}
		}

		// stop 暫停時間
		stop() {
			this.isStop = true;
			this.tweens.forEach(function (tween) {
				tween.stop();
			});
		}

		start() {
			this.isStop = false;
			this.tweens.forEach(function (tween) {
				tween.start();
			});
		}
	}

	// window.Time = Time;

	// for (let i = 0; i < 10000; i += 100) {
	// 	window['TIME_' + i] = window.env === 'develop' ? 0 : i;
	// }

	// import THREE from 'three';

	const waveVertexShader = `
		uniform float size;
		uniform float frequency1;
		uniform float frequency2;
		uniform float offset1;
		uniform float offset2;
		uniform float waveHeight1;
		uniform float waveHeight2;

		void main() { 
			vec3 curPos;
			curPos = vec3(position.x, 1, position.z);
			curPos[1] = cos(position.x * frequency1 + offset1) * sin(position.z * frequency1 + offset1) * waveHeight1 + cos(position.x * frequency2 + offset2) * sin(position.z * frequency2 + offset2) * waveHeight2;


			gl_PointSize = size;//min(size * curPos[1] * 0.2, 4.0);
			gl_Position = projectionMatrix * modelViewMatrix * vec4( curPos, 1.0 ); 
		}
	`;

	const waveFragmentShader = `
		uniform sampler2D texture; 
		uniform vec3 color; 
		uniform float opacity; 
		void main() { 
			
			gl_FragColor = vec4(color, opacity) * texture2D( texture, gl_PointCoord ); 
			//  gl_FragColor = vec4(1,1,1,1); 
		}
	`;

	// let pointImg = './point.png';
	const pointCvs = document.createElement('canvas');
	const pointCtx = pointCvs.getContext('2d');

	pointCvs.width = 32;
	pointCvs.height = 32;

	var grd = pointCtx.createRadialGradient(16, 16, 5, 16, 16, 16);
	grd.addColorStop(0, 'rgba(0, 0, 0, 0.3)');
	grd.addColorStop(1, 'rgba(0, 0, 0, 0)');

	pointCtx.fillStyle = grd;
	pointCtx.fillRect(0, 0, 32, 32);

	class Wave extends Time {
		constructor(options) {
			super();

			const defaults = {
				color: '#000',
				opacity: 1,
				position: new THREE.Vector3(),
				xCount: 100,
				zCount: 100,

				xDis: 200,
				zDis: 200,

				size: 1, // 點大小
				frequency1: 0.2,
				frequency2: 0.1,

				maxWaveHeight1: 10,
				minWaveHeight1: 3,
				maxWaveHeight2: 8,
				minWaveHeight2: 5,

				initOffset1: 0,
				initOffset2: 0,
				offsetSpeed1: 2,
				offsetSpeed2: 4,
				offsetSign: 1, // -1 or 1
			};

			for (const key in defaults) {
				options[key] = options[key] || defaults[key];
			}
			options.xStep = options.xDis / options.xCount;
			options.zStep = options.zDis / options.zCount;

			this.options = options;

			this.tick = null;

			this.offset1 = options.initOffset1;
			this.offset2 = options.initOffset2;

			this.particlePositions = null;
			this.obj = this.create();
		}

		create() {
			const options = this.options;

			const particlesGeom = new THREE.BufferGeometry();
			const particlePositions = new Float32Array(options.xCount * options.zCount * 3);

			const uniforms = {
				texture: {
					value: new THREE.CanvasTexture(pointCvs),
				},
				color: {
					value: new THREE.Color(options.color),
				},
				opacity: {
					type: 'float',
					value: options.opacity,
				},
				size: {
					type: 'float',
					value: options.size * 10,
				},
				frequency1: {
					type: 'float',
					value: options.frequency1,
				},
				frequency2: {
					type: 'float',
					value: options.frequency2,
				},
				offset1: {
					type: 'float',
					value: 0,
				},
				offset2: {
					type: 'float',
					value: 0,
				},
				waveHeight1: {
					type: 'float',
					value: 0,
				},
				waveHeight2: {
					type: 'float',
					value: 0,
				},
			};

			var shaderMaterial = new THREE.ShaderMaterial({
				uniforms: uniforms,
				vertexShader: waveVertexShader,
				fragmentShader: waveFragmentShader,

				blending: THREE.AdditiveBlending,
				depthTest: false,
				transparent: true,
			});

			let count = 0;
			for (let x = 0; x < options.xCount; x++) {
				for (let z = 0; z < options.zCount; z++) {
					particlePositions[count++] = x * options.xStep;
					particlePositions[count++] = 0; //  y
					particlePositions[count++] = z * options.zStep;
				}
			}

			this.particlePositions = particlePositions;

			particlesGeom.setDrawRange(0, options.xCount * options.zCount);
			particlesGeom.addAttribute(
				'position',
				new THREE.BufferAttribute(particlePositions, 3).setDynamic(true)
			);
			particlesGeom.computeBoundingBox();
			particlesGeom.center();

			const points = new THREE.Points(particlesGeom, shaderMaterial);
			points.position.copy(options.position);
			points.rotation.y = Math.random() * 0.2;

			return points;
		}

		start() {
			this.tick = this.addTick(this.update);

			const that = this;
			function changeWHP(waveHeight) {
				that.obj.material.uniforms.waveHeight1.value = waveHeight.waveHeight1;
				that.obj.material.uniforms.waveHeight2.value = waveHeight.waveHeight2;
			}

			const waveHeight = {
				waveHeight1: this.options.minWaveHeight1,
				waveHeight2: this.options.minWaveHeight2,
			};
			const tween1 = new TWEEN.Tween(waveHeight)
				.to(
					{
						waveHeight1: this.options.maxWaveHeight1,
						waveHeight2: this.options.maxWaveHeight2,
					},
					3000
				)
				.easing(TWEEN.Easing.Cubic.InOut)
				.onUpdate(changeWHP);

			const tween2 = new TWEEN.Tween(waveHeight)
				.to(
					{
						waveHeight1: this.options.minWaveHeight1,
						waveHeight2: this.options.minWaveHeight2,
					},
					3000
				)
				.easing(TWEEN.Easing.Cubic.InOut)
				.onUpdate(changeWHP);

			this.addTween(tween1);
			tween1.chain(tween2);
			tween2.chain(tween1);
			tween1.start();
		}

		stop() {
			this.removeTick(this.tick);
		}

		update(delta) {
			const options = this.options;
			const second = delta / 1000;

			this.obj.material.uniforms.offset1.value += second * options.offsetSpeed1 * options.offsetSign;
			this.obj.material.uniforms.offset2.value += second * options.offsetSpeed2 * options.offsetSign;
		}
	}

	class Ani extends Time {
		constructor(options) {
			super();

			const targetStr = options.target || 'body';
			this.target = document.querySelector(targetStr);

			this.size = {};
			this.size.width = this.target === 'body' ? window.innerWidth : this.target.clientWidth;
			this.size.height = this.target === 'body' ? (window.innerWidth * 9) / 16 : this.target.clientHeight;

			this.waves = [];
			this.tick = null;

			this.scene = new THREE.Scene(); // 場景

			this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000); // 透視相機
			this.camera.position.set(0, 6, 150); // 相機位置
			this.scene.add(this.camera); // add到場景中
			// this.scene.fog = new THREE.Fog(0x000000, 100, 500);

			this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true }); // 渲染
			this.renderer.setClearColor(0x000000, 0);
			this.renderer.setSize(this.size.width, this.size.height);

			this.target.appendChild(this.renderer.domElement); // 將渲染Element新增到Dom中
		}

		resize() {
			this.camera.aspect = 16 / 9;
			this.renderer.setSize(this.size.width, this.size.height);
		}

		addWave(wave) {
			this.waves.push(wave);
			this.scene.add(wave.obj);
		}

		start() {
			this.waves.forEach((w) => w.start());
			this.tick = this.addTick(this.update);
		}

		stop() {
			this.removeTick(this.tick);
		}

		update() {
			this.renderer.render(this.scene, this.camera);
		}
	}

	function buildWave() {
		let aniPlaying = false;
		const wave1 = new Wave({
			color: 0x3062ff,
			opacity: 0.7,
			position: new THREE.Vector3(),
			xCount: 300,
			zCount: 300,
			xDis: 200, //  x 寬
			zDis: 200, //  z 寬
			size: 0.6, //  點大小
			frequency1: 0.03,
			frequency2: 0.06,

			maxWaveHeight1: 15,
			minWaveHeight1: 10,
			maxWaveHeight2: 12,
			minWaveHeight2: 10,

			initOffset1: 0,
			initOffset2: 0,
			offsetSpeed1: 0.6,
			offsetSpeed2: 0.4,
			offsetSign: 1, //  -1 or 1
		});

		const waveEl = document.getElementById('wave');

		const ani = new Ani({
			target: '#wave', // 生成的目標
		});
		ani.addWave(wave1);

		// ani.start();
		// aniPlaying = true

		window.addEventListener('resize', () => {
			ani.resize();
		});

		TIME.start();

		const scrollHandlerThrottle = throttle(scrollHandler, 20, 50); // 節流作用
		function scrollHandler() {
			if (isInViewport(waveEl) && !aniPlaying) {
				aniPlaying = true;
				ani.start();
			} else if (!isInViewport(waveEl)) {
				aniPlaying = false;
				ani.stop();
			}
		}

		const breakpoint = window.matchMedia('(min-width: 1024px)');
		const breakpointChecker = function() {
			if (breakpoint) {
				// Large
				window.addEventListener('scroll', scrollHandlerThrottle);
				scrollHandler();
			} else {
				// Small + Medium
				window.removeEventListener('scroll', scrollHandlerThrottle);
				aniPlaying = false;
				ani.stop();
			}
		}

		breakpointChecker();
		breakpoint.addListener(breakpointChecker);
	}

	window.addEventListener('load', buildWave);

	// ===============================

	// 簡單的節流函數
	function throttle(func, wait, mustRun) {
		var timeout,
			startTime = new Date();
	
		return function() {
			var context = this,
				args = arguments,
				curTime = new Date();
	
			clearTimeout(timeout);
			// 如果達到了規定的觸發時間間隔，觸發 handler
			if(curTime - startTime >= mustRun){
				func.apply(context,args);
				startTime = curTime;
			// 沒達到觸發間隔，重新設定定時器
			}else{
				timeout = setTimeout(func, wait);
			}
		};
	};
	
})(window, document);
